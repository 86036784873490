<template>
  <b-card>
    <b-row class="delete-margin-title-result">
      <b-col
        :cols="12"
        class="text-center mt-2"
      >
        <b-form-group
          label-for=""
        >
          <h3>
            รายงานการรับค่าเช่ารายวัน
          </h3>
          <h4
            v-if="transDate"
            class="mt-1"
          >
            ข้อมูลวันที่ {{ resolveFormatDate(transDate) }}
          </h4>
          <h4
            v-if="effDate"
            class="mt-1"
          >
            เงินโอนวันที่ {{ resolveFormatDate(effDate) }}
          </h4>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="delete-margin-table-result">
      <b-col :cols="12">
        <b-form-group
          label-for=""
        >
          <b-table-simple
            caption-top
            bordered
            class="position-relative mb-1"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="text-center">
                  ลำดับ
                </b-th>
                <b-th class="text-center">
                  พอร์ต
                </b-th>
                <b-th class="text-center">
                  ข้อมูลวันที่และเวลา
                </b-th>
                <b-th class="text-center">
                  โอนวันที่และเวลา
                </b-th>
                <b-th class="text-center">
                  เลขที่รายการ
                </b-th>
                <b-th class="text-center">
                  ลูกค้า
                </b-th>
                <b-th class="text-center">
                  ประเภทค่าใช้จ่าย
                </b-th>
                <b-th class="text-center">
                  ยอดเงิน
                </b-th>
                <b-th class="text-center">
                  เลขทะเบียน
                </b-th>
                <b-th class="text-center">
                  ผู้ดูแล
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="dataResult.length">
                <b-tr
                  v-for="(item, index) in paginateArray(
                    formatDataResult,
                    perPage,
                    currentPage
                  )"
                  :key="index"
                >
                  <b-td class="text-center">
                    {{ item.order }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.port_desc }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.transfer_time }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.payment_time }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.transfer_number }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.customer_name }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.transaction_desc }}
                  </b-td>
                  <b-td class="text-right">
                    <span :class="`${!item.order ? 'sum-payment-amount' : null}`">
                      {{ Number(item.payment_amount).toLocaleString() }}
                    </span>
                  </b-td>
                  <b-td class="text-center">
                    {{ item.regnum }}
                  </b-td>
                  <b-td class="text-center">
                    {{ item.follower }}
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="delete-margin-table-result">
      <b-col
        :cols="12"
        class="text-center"
      >
        <b-form-group
          label-for=""
        >
          <b-table-simple
            caption-top
            bordered
            class="position-relative mb-1"
            style="max-width: 800px;"
          >
            <b-tbody>
              <template v-if="transResult.length != 0">
                <b-tr
                  v-for="(item, index) in transResult"
                  :key="index"
                >
                  <b-td
                    v-if="index == 0"
                    class="text-center"
                    style="width: 20%; vertical-align: top;"
                    :rowspan="transResult.length"
                  >
                    รวม
                  </b-td>
                  <b-td
                    class="text-right"
                    style="width: 40%;"
                  >
                    {{ item.transaction_desc }}
                  </b-td>
                  <b-td
                    class="text-center"
                    style="width: 20%;"
                  >
                    {{ Number(item.payment_item).toLocaleString() }} รายการ
                  </b-td>
                  <b-td
                    class="text-right"
                    style="width: 20%;"
                  >
                    {{ Number(item.payment_amount).toLocaleString() }} บาท
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataResult.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-for=""
        >
          <div style="display: inline;">
            <b-button
              variant="primary"
              @click="fncClickExportExcel"
            >
              ดาวน์โหลด Excel
            </b-button>
          </div>
          <div
            class="ml-1"
            style="display: inline;"
          >
            <b-button
              variant="primary"
              @click="fncClickExportPDF"
            >
              ดาวน์โหลด PDF
            </b-button>
          </div>
          <div
            class="ml-1"
            style="display: inline;"
          >
            <b-button
              variant="outline-secondary"
              @click="fncResetForm()"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BPagination, BButton,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import XLSX from 'xlsx' // import xlsx
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/named
import { THSarabunNewNormal, defaultVFS } from '@/jspdf/THSarabunNew-normal'
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/named
import { THSarabunNewBold } from '@/jspdf/THSarabunNew-bold'
import { jsPDF } from 'jspdf'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BPagination,
    BButton,
  },

  props: {
    transDate: {
      type: String,
      default: null,
    },

    effDate: {
      type: String,
      default: null,
    },

    dataResult: {
      type: [Object, Array],
      required: true,
    },

    transResult: {
      type: [Object, Array],
      required: true,
    },
  },

  setup(props, { emit }) {
    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล [Result] */
    const fncResetForm = () => {
      emit('reset-result')
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    // กำหนดรูปแบบการแสดงผลให้กับ DataResult
    const formatDataResult = computed(() => {
      // eslint-disable-next-line prefer-const
      let setFormatDataResult = []
      let sumBetweenPort = 0
      let sumAll = 0

      Object.keys(props.dataResult).forEach(key => {
        setFormatDataResult.push({
          order: Number(key) + 1,
          port_desc: props.dataResult[key].port_desc,
          transfer_time: props.dataResult[key].transfer_time,
          payment_time: props.dataResult[key].payment_time,
          transfer_number: props.dataResult[key].transfer_number,
          customer_name: props.dataResult[key].customer_name,
          transaction_desc: props.dataResult[key].transaction_desc,
          payment_amount: props.dataResult[key].payment_amount,
          regnum: props.dataResult[key].regnum,
          follower: props.dataResult[key].follower,
        })

        if (!props.dataResult[(Number(key) + 1)] || (props.dataResult[(Number(key) + 1)] && props.dataResult[key].port_desc !== props.dataResult[Number(key) + 1].port_desc)
        ) {
          setFormatDataResult.push({
            order: '',
            port_desc: '',
            transfer_time: '',
            payment_time: '',
            transfer_number: '',
            customer_name: '',
            transaction_desc: 'ผลรวม',
            payment_amount: sumBetweenPort,
            regnum: '',
            follower: '',
          })

          sumBetweenPort = 0
        }

        sumBetweenPort += props.dataResult[key].payment_amount
        sumAll += props.dataResult[key].payment_amount
      })

      setFormatDataResult.push({
        order: '',
        port_desc: '',
        transfer_time: '',
        payment_time: '',
        transfer_number: '',
        customer_name: '',
        transaction_desc: 'รวมทั้งสิ้น',
        payment_amount: sumAll,
        regnum: '',
        follower: '',
      })

      return setFormatDataResult
    })

    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    const fncClickExportExcel = () => {
      const setJsonValue = []

      Object.keys(formatDataResult.value).forEach(key => {
        setJsonValue.push({
          พอร์ต: formatDataResult.value[key].port_desc,
          ข้อมูลวันที่และเวลา: formatDataResult.value[key].transfer_time,
          โอนวันที่และเวลา: formatDataResult.value[key].payment_time,
          เลขที่รายการ: formatDataResult.value[key].transfer_number,
          ลูกค้า: formatDataResult.value[key].customer_name,
          ประเภทค่าใช้จ่าย: formatDataResult.value[key].transaction_desc,
          ยอดเงิน: formatDataResult.value[key].payment_amount,
          เลขทะเบียน: formatDataResult.val[key].regnum,
          ผู้ดูแล: formatDataResult.val[key].follower,
        })
      })

      const dataWS = XLSX.utils.json_to_sheet(setJsonValue)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'รายงานรับชำระรายวัน.xlsx')
    }

    const fncClickExportPDF = () => {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('l', 'mm', [297, 210])

      doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal')
      doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold')

      doc.setFontSize(16)
      doc.setFont('THSarabunNew', 'bold')

      doc.text(148.5, 20, 'รายงานการรับค่าเช่ารายวัน', 'center')

      doc.setFontSize(18)
      doc.text(10, 33, `${props.titleResult ? props.titleResult : ''}`)

      doc.setLineWidth(0.1)
      doc.line(10, 35, 287, 35) // horizontal line (เส้นแนวนอน)

      doc.line(10, 35, 10, 42) // vertical line
      doc.line(18, 35, 18, 42) // vertical line order
      doc.line(40, 35, 40, 42) // vertical line transfer_time
      doc.line(70, 35, 70, 42) // vertical line payment_time
      doc.line(100, 35, 100, 42) // vertical line transfer_number
      doc.line(135, 35, 135, 42) // vertical line customer_name
      doc.line(180, 35, 180, 42) // vertical line transaction_desc
      doc.line(210, 35, 210, 42) // vertical line payment_amount
      doc.line(230, 35, 230, 42) // vertical line connum
      doc.line(250, 35, 250, 42) // vertical line follower
      doc.line(287, 35, 287, 42) // vertical line end
      // doc.line(287, 35, 287, 42) // vertical line เบอร์โทร

      doc.line(10, 42, 287, 42) // horizontal line (เส้นแนวนอน)

      doc.setFontSize(12)
      doc.text(14, 40, 'ลำดับ', 'center')
      doc.text(29, 40, 'พอร์ต', 'center')
      doc.text(55, 40, 'ข้อมูลวันที่และเวลา', 'center')
      doc.text(85, 40, 'โอนวันที่และเวลา', 'center')
      doc.text(117.5, 40, 'เลขที่รายการ', 'center')
      doc.text(157.5, 40, 'ลูกค้า', 'center')
      doc.text(195, 40, 'ประเภทค่าใช้จ่าย', 'center')
      doc.text(220, 40, 'ยอดเงิน', 'center')
      doc.text(240, 40, 'เลขทะเบียน', 'center')
      doc.text(268.5, 40, 'ผู้ดูแล', 'center')
      // doc.text(273.5, 40, 'เบอร์โทร', 'center')

      doc.setFont('THSarabunNew', 'normal')
      let currentX = 42

      Object.keys(formatDataResult.value).forEach(key => {
        doc.line(10, currentX, 10, currentX + 6) // vertical line
        doc.text(14, currentX + 4, `${formatDataResult.value[key].order}`, 'center')
        doc.line(18, currentX, 18, currentX + 6) // vertical line จำนวนวันที่ค้าง
        doc.text(29, currentX + 4, `${formatDataResult.value[key].port_desc}`, 'center')
        doc.line(40, currentX, 40, currentX + 6) // vertical line เลขทะเบียน
        doc.text(55, currentX + 4, `${formatDataResult.value[key].transfer_time}`, 'center')
        doc.line(70, currentX, 70, currentX + 6) // vertical line ลูกค้า
        doc.text(85, currentX + 4, `${formatDataResult.value[key].payment_time}`, 'center')
        doc.line(100, currentX, 100, currentX + 6) // vertical line วันที่ 1
        doc.text(117.5, currentX + 4, `${formatDataResult.value[key].transfer_number}`, 'center')
        doc.line(135, currentX, 135, currentX + 6) // vertical line วันที่ 2
        doc.text(157.5, currentX + 4, `${formatDataResult.value[key].customer_name}`, 'center')
        doc.line(180, currentX, 180, currentX + 6) // vertical line วันที่ 3
        doc.text(195, currentX + 4, `${formatDataResult.value[key].transaction_desc}`, 'center')
        doc.line(210, currentX, 210, currentX + 6) // vertical line วันที่ 4
        doc.text(225, currentX + 4, `${formatDataResult.value[key].payment_amount ? Number(formatDataResult.value[key].payment_amount).toLocaleString() : ''}`, 'right')
        doc.line(230, currentX, 230, currentX + 6) // vertical line วันที่ 5
        doc.text(240, currentX + 4, `${formatDataResult.value[key].regnum}`, 'center')
        doc.line(250, currentX, 250, currentX + 6) // vertical line มากกว่า 5
        doc.text(268.5, currentX + 4, `${formatDataResult.value[key].follower}`, 'center')
        doc.line(287, currentX, 287, currentX + 6) // vertical line รวม
        // doc.text(261, currentX + 4, `${formatDataResult.value[key].follower}`)
        // doc.line(287, currentX, 287, currentX + 6) // vertical line เบอร์โทร

        doc.line(10, currentX + 6, 287, currentX + 6) // horizontal line (เส้นแนวนอน)

        currentX += 6

        if (currentX >= 190) {
          doc.addPage()

          doc.setFontSize(16)
          doc.setFont('THSarabunNew', 'bold')

          doc.text(148.5, 20, 'รายงานการรับค่าเช่ารายวัน', 'center')

          doc.setFontSize(18)
          doc.text(10, 33, `${props.titleResult}`)

          doc.line(10, 35, 287, 35) // horizontal line (เส้นแนวนอน)

          doc.line(10, 35, 10, 42) // vertical line
          doc.line(18, 35, 18, 42) // vertical line order
          doc.line(40, 35, 40, 42) // vertical line transfer_time
          doc.line(70, 35, 70, 42) // vertical line payment_time
          doc.line(100, 35, 100, 42) // vertical line transfer_number
          doc.line(135, 35, 135, 42) // vertical line customer_name
          doc.line(180, 35, 180, 42) // vertical line transaction_desc
          doc.line(210, 35, 210, 42) // vertical line payment_amount
          doc.line(230, 35, 230, 42) // vertical line connum
          doc.line(250, 35, 250, 42) // vertical line follower
          doc.line(287, 35, 287, 42) // vertical line end
          // doc.line(287, 35, 287, 42) // vertical line เบอร์โทร

          doc.line(10, 42, 287, 42) // horizontal line (เส้นแนวนอน)

          doc.setFontSize(12)
          doc.text(14, 40, 'ลำดับ', 'center')
          doc.text(29, 40, 'พอร์ต', 'center')
          doc.text(55, 40, 'ข้อมูลวันที่และเวลา', 'center')
          doc.text(85, 40, 'โอนวันที่และเวลา', 'center')
          doc.text(117.5, 40, 'เลขที่รายการ', 'center')
          doc.text(157.5, 40, 'ลูกค้า', 'center')
          doc.text(195, 40, 'ประเภทค่าใช้จ่าย', 'center')
          doc.text(220, 40, 'ยอดเงิน', 'center')
          doc.text(240, 40, 'เลขทะเบียน', 'center')
          doc.text(268.5, 40, 'ผู้ดูแล', 'center')

          currentX = 42

          doc.setFont('THSarabunNew', 'normal')
        }
      })

      currentX += 10
      doc.line(10, currentX - 6, 153, currentX - 6) // horizontal line
      doc.text(30, currentX, 'รวม', 'right')
      // doc.line(10, currentX + 10, 135, currentX + 10)

      props.transResult.forEach(item => {
        doc.line(10, currentX - 6, 10, currentX + 2) // vertical line [1]
        doc.line(46, currentX + 2, 153, currentX + 2) // horizontal line
        doc.line(46, currentX - 6, 46, currentX + 2) // vertical line [2]
        // console.log(item.transaction_desc)
        doc.text(70, currentX, `${item.transaction_desc}`, 'center')
        doc.line(87, currentX - 6, 87, currentX + 2) // vertical line [3]
        doc.text(103.5, currentX, `${Number(item.payment_item).toLocaleString()}`, 'center')
        doc.line(120, currentX - 6, 120, currentX + 2) // vertical line [4]
        doc.text(136.5, currentX, `${Number(item.payment_amount).toLocaleString()} บาท`)
        doc.line(153, currentX - 6, 153, currentX + 2) // vertical line order [3]
        currentX += 8
      })

      doc.line(10, currentX - 6, 46, currentX - 6) // horizontal line

      const pages = doc.internal.getNumberOfPages()
      const pageWidth = doc.internal.pageSize.width
      const pageHeight = doc.internal.pageSize.height

      // eslint-disable-next-line no-plusplus
      for (let j = 1; j < pages + 1; j++) {
        const horizontalPos = pageWidth / 2
        const verticalPos = pageHeight - 10
        doc.setPage(j)
        doc.setFontSize(14)
        doc.text(`${j} / ${pages}`, horizontalPos, verticalPos, {
          align: 'center',
        })
      }

      doc.output('dataurlnewwindow') // คำสั่ง Export *** dataurlnewwindow = เปิดแท็บใหม่
    }

    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncResetForm,
      fncClickExportExcel,
      fncClickExportPDF,

      // ส่วนการทำงานแสดงผล UI
      formatDataResult,
      paginateArray,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,

      // Export import Lib
      THSarabunNewNormal,
      THSarabunNewBold,
      defaultVFS,
    }
  },
}
</script>

<style lang="scss">
.sum-payment-amount {
  text-decoration-line: underline;
  text-decoration-style: double;
}

/* for local only */
// .delete-margin-title-result {
//   margin-bottom: -40px;
// }

// .delete-margin-table-result {
//   margin-bottom: -40px;
// }
</style>
