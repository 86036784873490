<template>
  <div>
    <template v-if="!dataResult.length">
      <b-card title="รายงานการรับค่าเช่ารายวัน">
        <daily-receive-report-popup-branch
          :temp-search-branch="tempSearchBranch"
          :is-popup-branch-active.sync="isPopupBranchActive"
          @select-item="fncSelectBranch"
        />
        <daily-receive-report-popup-trans-select
          :temp-search-trans-select="tempSearchTransSelect"
          :is-popup-trans-select-active.sync="isPopupTransSelectActive"
          @select-item="fncSelectTransSelect"
        />
        <daily-receive-report-popup-follower
          :temp-search-follower="tempSearchFollower"
          :is-popup-follower-active.sync="isPopupFollowerActive"
          @select-item="fncSelectFollower"
        />
        <b-modal
          ref="refModalDatePickerTransDate"
          centered
          title="ข้อมูลวันที่"
          hide-footer
          size="sm"
          modal-class="modalDatePicker"
        >
          <v-date-picker
            v-model="transDate"
            class="mt-6"
            locale="th"
            @input="refModalDatePickerTransDate.hide()"
          />
        </b-modal>
        <b-modal
          ref="refModalDatePickerEffDate"
          centered
          title="เงินโอนวันที่"
          hide-footer
          size="sm"
          modal-class="modalDatePicker"
        >
          <v-date-picker
            v-model="effDate"
            class="mt-6"
            locale="th"
            @input="refModalDatePickerEffDate.hide()"
          />
        </b-modal>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="branchCode"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  พอร์ต
                </div>
              </template>
              <b-input-group v-show="!branchCode">
                <b-form-input
                  id="branchCode"
                  v-model="inputSearchBranch"
                  placeholder="ค้นหาพอร์ต"
                  trim
                  @keydown.enter.native="fncSearchBranch(inputSearchBranch)"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupBranch()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="branchCode">
                <b-form-input
                  id="branchCode"
                  :value="branchName"
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelBranch"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  ข้อมูลวันที่
                </div>
              </template>
              <b-form-input
                id="inputTransDate"
                :value="resolveFormatDate(transDate)"
                trim
                readonly
                placeholder="ข้อมูลวันที่"
                @click="fncShowDatepickerTransDate()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="transSelect"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  รับชำระค่า
                </div>
              </template>
              <b-input-group v-show="!transSelectCode">
                <b-form-input
                  id="transSelect"
                  v-model="inputSearchTransSelect"
                  placeholder="ค้นหารับชำระค่า"
                  trim
                  @keydown.enter.native="fncSearchTransSelect(inputSearchTransSelect)"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupTransSelect()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="transSelectCode">
                <b-form-input
                  id="transSelect"
                  :value="transSelectName"
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelTransSelect"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เงินโอนวันที่
                </div>
              </template>
              <b-form-input
                id="inputEffDate"
                :value="resolveFormatDate(effDate)"
                trim
                readonly
                placeholder="เงินโอนวันที่"
                @click="fncShowDatepickerEffDate()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="follower"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  ผู้ดูแล
                </div>
              </template>
              <b-input-group v-show="!follower">
                <b-form-input
                  id="follower"
                  v-model="inputSearchFollower"
                  placeholder="ค้นหาผู้ดูแล"
                  trim
                  @keydown.enter.native="fncSearchFollower(inputSearchFollower)"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupFollower()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="follower">
                <b-form-input
                  id="follower"
                  :value="follower"
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelFollower"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  @click="fncSubmit()"
                >
                  ยืนยัน
                </b-button>
              </b-overlay>
              <b-button
                class="ml-1"
                variant="outline-secondary"
                @click="fncResetForm()"
              >
                ยกเลิก
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-else>
      <!-- Component สำหรับแสดงผลลัพธ์ที่ได้จากการ Filter -->
      <daily-receive-report-result
        :trans-date="transDate"
        :eff-date="effDate"
        :data-result="dataResult"
        :trans-result="transResult"
        @reset-result="fncResetResult()"
      />
    </template>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BModal, BOverlay,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store'
import {
  VDatePicker,
} from 'vuetify/lib'
import DailyReceiveReportPopupBranch from './daily-receive-report-popup/DailyReceiveReportPopupBranch.vue'
import DailyReceiveReportPopupTransSelect from './daily-receive-report-popup/DailyReceiveReportPopupTransSelect.vue'
import DailyReceiveReportPopupFollower from './daily-receive-report-popup/DailyReceiveReportPopupFollower.vue'
import DailyReceiveReportResult from './DailyReceiveReportResult.vue'
import DailyReceiveReportStoreModule from './DailyReceiveReportStoreModule'

// Notification
// eslint-disable-next-line import/order
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    BOverlay,
    VDatePicker,
    DailyReceiveReportPopupBranch,
    DailyReceiveReportPopupTransSelect,
    DailyReceiveReportPopupFollower,
    DailyReceiveReportResult,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appDailyReceiveReport'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, DailyReceiveReportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ประกาศตัวแปร Ref
    const refModalDatePickerTransDate = ref(null)
    const refModalDatePickerEffDate = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchTransSelect = ref(null)
    const inputSearchFollower = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const branchCode = ref(null)
    const branchName = ref(null)
    const transSelectCode = ref(null)
    const transSelectName = ref(null)
    const transDate = ref(null)
    const effDate = ref(null)
    const transResult = ref([])
    const dataResult = ref([])
    const tempSearchBranch = ref(null)
    const tempSearchTransSelect = ref(null)
    const tempSearchFollower = ref(null)

    const follower = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupTransSelectActive = ref(false)
    const isPopupFollowerActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกผู้ดูแลจาก Popup */
    const fncSelectFollower = data => {
      follower.value = data.acccodfol
    }

    /* ฟังก์ชั่นยกเลิกผู้ดูแล */
    const fncCancelFollower = () => {
      follower.value = null
    }

    /* ฟังก์ชั่นเปิด Popup รับชำระค่า */
    const fncOpenPopupTransSelect = search => {
      if (search) tempSearchTransSelect.value = search
      else tempSearchTransSelect.value = null

      isPopupTransSelectActive.value = true
    }

    /* ฟังก์ชั่นค้นหาวิธีชำระ */
    const fncSearchTransSelect = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'RT_TRANSACTION',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              transSelectCode.value = getSearchResult[0].tabkeytwo
              transSelectName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupTransSelect(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกรับชำระค่า */
    const fncCancelTransSelect = () => {
      transSelectCode.value = null
      transSelectName.value = null
      inputSearchTransSelect.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกรับชำระค่าจาก Popup */
    const fncSelectTransSelect = data => {
      transSelectCode.value = data.tabkeytwo
      transSelectName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup ผู้ดูแล */
    const fncOpenPopupFollower = search => {
      if (search) tempSearchFollower.value = search
      else tempSearchFollower.value = null

      isPopupFollowerActive.value = true
    }

    /* ฟังก์ชั่นค้นหาวิธีชำระ */
    const fncSearchFollower = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'POPUP',
          function: 'GET',
          usrcod: JSON.parse(localStorage.getItem('userData')).member_no,
          TEXT_SEARCH: null,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_FOLLOWER`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.acccodfol.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              follower.value = getSearchResult[0].acccodfol
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupTransSelect(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerTransDate = () => {
      refModalDatePickerTransDate.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker เงินโอนวันที่ */
    const fncShowDatepickerEffDate = () => {
      refModalDatePickerEffDate.value.show()
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      fncCancelBranch()
      fncCancelTransSelect()
      transDate.value = null
      effDate.value = null
      follower.value = null
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล [Result] */
    const fncResetResult = () => {
      transResult.value = []
      dataResult.value = []

      fncResetForm()
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    const fncSubmit = () => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)

      const accesstime = new Date()

      const payload = {
        action: 'REPORT',
        events: 'RECEIVE',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        accesstime: moment(accesstime).format('YYYY-MM-DD'),
        cpncod_in: '0001',
        cpnbrncod_in: branchCode.value,
        trans_date_in: transDate.value,
        trans_select_in: transSelectCode.value,
        effective_date_in: effDate.value,
        follower_in: follower.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RPT_DAILY_RECEIVE`, payload)
        .then(response => {
          if (response.data.responseData.length) {
            dataResult.value = response.data.responseData

            const payloadTrans = {
              action: 'REPORT',
              events: 'RECEIVE',
              function: 'SUBMIT',
              username: JSON.parse(localStorage.getItem('userData')).member_no,
              accesstime: moment(accesstime).format('YYYY-MM-DD'),
              cpncod_in: '0001',
              cpnbrncod_in: branchCode.value,
              trans_date_in: transDate.value,
              trans_select_in: transSelectCode.value,
              effective_date_in: effDate.value,
              follower_in: follower.value,
            }

            store
              .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RPT_DAILY_RECEIVE_TRANS`, payloadTrans)
              .then(responseTrans => {
                transResult.value = responseTrans.data.responseData
              })
          } else {
            /* eslint-disable no-console */
            toast.error({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: 'ไม่พบข้อมูลที่ค้นหา กรุณาลองใหม่อีกครั้ง',
              },
            })
          }
        })
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      // ประกาศตัวแปร Ref
      refModalDatePickerTransDate,
      refModalDatePickerEffDate,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputSearchTransSelect,
      inputSearchFollower,

      // ประกาศตัวแปรสำหรับเก็บค่า
      branchCode,
      branchName,
      transSelectCode,
      transSelectName,
      transDate,
      effDate,
      transResult,
      dataResult,
      tempSearchBranch,
      tempSearchTransSelect,
      follower,
      tempSearchFollower,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupTransSelectActive,
      isPopupFollowerActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncSearchBranch,
      fncOpenPopupBranch,
      fncCancelBranch,
      fncSelectBranch,
      fncOpenPopupTransSelect,
      fncSearchTransSelect,
      fncCancelTransSelect,
      fncSelectTransSelect,
      fncShowDatepickerTransDate,
      fncShowDatepickerEffDate,
      fncResetForm,
      fncResetResult,
      fncSubmit,
      fncSelectFollower,
      fncCancelFollower,
      fncOpenPopupFollower,
      fncSearchFollower,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only */
/* .delete-margin {
  margin-bottom: -60px !important;
} */
</style>
